import { KeyValuePairs } from '@aws-northstar/ui'
import {
	ColumnLayout,
	Container,
	ExpandableSection,
	Header,
} from '@cloudscape-design/components'

type AccessDetailsProps = {
	microserviceName: string
	applicationName: {
		label: string
		value: string
	}
}

const AccessDetails: React.FC<AccessDetailsProps> = ({
	microserviceName,
	applicationName
}) => {
	const baseUrl = `https://gitlab.com/globetelecom/infrastructure/gcp/application/atlas/core-pipelines/atlas-microservices/${applicationName.label}`
	const generalDetailsKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}/atlas-microservice-${microserviceName}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Gitlab Microservice SubGroup`}
					</a>
				)
			}
		]
	]

	return (
		<Container header={<Header variant="h3">Access Details</Header>}>
			{/* Commenting to update here */}
			<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
				For accesses to the links below, you may reach out to Platform
				Engineering group (isg-ceso-platengr@globe.com.ph)
			</p>
			<ColumnLayout columns={2} variant="text-grid">
				<ExpandableSection headerText="What's Next?">
					<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
					Please allow a few seconds before checking your Microservice merge request in the link.
					</p>
					<ColumnLayout columns={2} variant="text-grid">
						<KeyValuePairs
							key={'generalDetails'}
							items={generalDetailsKeyValuePairsItems}
						/>
					</ColumnLayout>
				</ExpandableSection>
			</ColumnLayout>
		</Container>
	)
}

export default AccessDetails
