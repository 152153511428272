import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'

import { useNewMicroserviceFormStore } from '../../../stores/microservices/newMicroserviceFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewMicroservice from '../../../hooks/mutate/useCreateNewMicroservice'
import type { MicroserviceRequest } from '../../../types/microservices'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import useApplications from '../../../hooks/query/useApplications'
import useMicroservices from '../../../hooks/query/useMicroservices'

const CODE_TEMPLATE_LIST = [
	// { label: 'NodeJS', value: 'nodejs' },
	{ label: 'NodeJS + CXS', value: 'cxs-nodejs' }
]

const MICROSERVICE_ENDPOINT_LIST = [
	{ label: 'Internal', value: 'internal' },
	{ label: 'External', value: 'external' },
	{ label: 'Internal + External', value: 'both' }
]

const NewMicroserviceForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const [applicationOptions, setApplicationOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: applications } = useApplications()
	const { data : microservices } = useMicroservices()

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewMicroserviceFormStore()

	const {
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	} = useGenericTagsManagementFormStore()

	const { mutate: createNewMicroservice, isLoading } =
		useCreateNewMicroservice()
	const { mutate: createTags, isLoading: isCreateTagsLoading } =
		useCreateNewTags()
	const {
		control,
		setValue,
		setError,
		trigger,
		formState: { isValid },
		watch
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})
	const [showForm, setShowForm] = useState(false)
	const [costCenterPlaceholder, setCostCenterPlaceholder] = useState("Add Cost Center")
	const [wbsCodePlaceholder, setWbsCodePlaceholder] = useState("Add WBS Code")

	interface envOpt {
		label: string
		value: string
	}

	useEffect(() => {
		if (applications && applications.length > 0) {
			const filteredApplications = applications.filter(
				(application) => application.status === 'APPROVED'
			)
			const uniqueApplications: envOpt[] = []
			const applicationNames = new Set()

			filteredApplications.forEach((application) => {
				if (!applicationNames.has(application.details?.applicationName)) {
					applicationNames.add(application.details?.applicationName)
					uniqueApplications.push({
						label: application.details?.applicationName,
						value: application.applicationID
					})
				}
			})

			const extractedOptions = uniqueApplications

			setApplicationOptions(extractedOptions)
		}
	}, [applications])

	const checkDuplicateMicroservices = (newMicroservice: MicroserviceRequest) => {
		if (!microservices || microservices.length === 0) return false;
			const microserviceNames = new Set(
				microservices
					.filter((microservice) => microservice.status === 'APPROVED' || microservice.status === 'PENDING_APPROVAL')
					.map((microservice) => microservice.microserviceName)
			);
			return microserviceNames.has(newMicroservice.details.microserviceName);
	}

	useEffect(() => {
		const subscription = watch((value) => {
			const selectedApplicationId = value.applicationName?.value; 
			if (selectedApplicationId && applications) {
				// Find the selected application from the application list
				const selectedApplication = applications.find(
					(app) => app.applicationID === selectedApplicationId
				);
				if (selectedApplication) {
					const { wbsCode, costCenter } = selectedApplication.details;

					setFormValues('wbsCode', wbsCode || '');
					setFormValues('costCenter', costCenter || '');
					setCostCenterPlaceholder(costCenter || "N/A");
					setWbsCodePlaceholder(wbsCode || "N/A");
				}
			}
		});
	
		return () => subscription.unsubscribe();
	}, [applications, setFormValues, watch]);

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const microservice: MicroserviceRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		const isDuplicate = checkDuplicateMicroservices(microservice);

		if (isDuplicate) {
			setError("microserviceName", {
				type: "manual",
				message: "The microservice with the same name already exists"
			});
			return;
		}
		
		createTags(newTag, {
			onSuccess: () => {
				createNewMicroservice(microservice, {
					onSuccess: () => {
						navigate('/microservices')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}

	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	// TEMPORARY APPLICATION VALUE FOR BETA API ONBOARDING - CXS
	useEffect(() => {
		if (applicationOptions.length > 0) {
			const cxsOption = applicationOptions.find(
				option => option.label === 'cxs'
			) || applicationOptions[0]

			setValue('applicationName', cxsOption)
			setFormValues('applicationName', cxsOption)
		}
	}, [applicationOptions, setValue, setFormValues])

	const handleYesClick = () => {
		setShowForm(true)
	}

	const handleNoClick = () => {
		navigate('/applications')
	}

	return (
		<div>
			{showForm ? (
				<Form
					actions={
						<SpaceBetween direction="horizontal" size="xs">
							<Box>
								<Button
									variant="primary"
									onClick={handleSubmit}
									loading={isLoading || isCreateTagsLoading}
								>
									Submit
								</Button>
								<Button variant="link" onClick={() => navigate(-1)}>
									Cancel
								</Button>
							</Box>
						</SpaceBetween>
					}
					header={
						<Header variant="h1" description="">
							Request a New Microservice
						</Header>
					}
				>
					<SpaceBetween direction="vertical" size="s">
						<Container>
							<SpaceBetween direction="vertical" size="l">
								<ColumnLayout columns={2} variant="default">
									<FormSelect
										label="Application Name"
										name="applicationName"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={applicationOptions}
										placeholder="Select Application"
										disabled={true}
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div style={{ width: '650px' }}>
											<FormSelect
												name="codeTemplate"
												label="Code Template"
												control={control}
												placeholder="Select Code Template"
												setFormValues={setFormValues}
												options={CODE_TEMPLATE_LIST}
												rules={{
													required: 'Please select a Code Template'
												}}
											/>
										</div>
									</div>
									<FormInput
										name="microserviceName"
										label="Name"
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Enter Display Name of Microservice"
										description="Max 20 characters"
										rules={{
											required: 'Please enter Microservice name',
											minLength: {
												value: 2,
												message: 'Minumum length of 2 characters is required'
											},
											pattern: {
												value: /^[a-z0-9-]+$/,
												message:
													'Microservice name should be lowercase only. Hyphens are permitted.'
											},
											validate: (value) => {
												if (microservices?.some((ms) => ms.status === 'APPROVED' && ms.microserviceName === value)) {
													return 'The microservice with the same name already exists';
												}
												return true;
											}
										}}
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div style={{ width: '650px' }}>
											<FormInput
												name="applicationUrl"
												label="Application URL Path"
												type="text"
												control={control}
												setFormValues={setFormValues}
												placeholder="Enter Application URL Path"
												description="The URL/path of application"
												rules={{
													required: 'Please enter application URL',
													minLength: {
														value: 2,
														message: 'Minumum length of 2 characters is required'
													}
												}}
											/>
										</div>
									</div>
									<FormInput
										name="portNumber"
										label="Port Number"
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Enter Port Number"
										rules={{
											required: 'Please enter port number'
										}}
									/>
									<FormSelect
										label="Endpoint"
										name="microserviceEndpoint"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={MICROSERVICE_ENDPOINT_LIST}
										placeholder="Select Microservice Endpoint"
									/>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<label style={{ fontWeight: '600', marginBottom: '5px' }}>
											WBS Code
										</label>
										<FormInput
												name="wbsCode"
												label=""
												type="text"
												description='Uses existing WBS Code from Application'
												disabled={true}
												control={control}
												setFormValues={setFormValues}
												placeholder={wbsCodePlaceholder}
												// rules={{
												// 	minLength: {
												// 	  value: 3,
												// 	  message: 'Minimum length should be 3'
												// 	}
												//   }}
											/>
									</div>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<label style={{ fontWeight: '600', marginBottom: '5px' }}>
											Cost Center
										</label>
										<FormInput
												name="costCenter"
												label=""
												type="text"
												description='Uses existing Cost Center from Application'
												disabled={true}
												control={control}
												setFormValues={setFormValues}
												placeholder={costCenterPlaceholder}
												// rules={{
												// 	minLength: {
												// 		value: 3,
												// 		message: 'Minimum length should be 3'
												// 	}
												//   }}
											/>
									</div>
								</ColumnLayout>
							</SpaceBetween>
						</Container>
					</SpaceBetween>
				</Form>
			) : (
				<div>
					<h2>Do you already have an application onboarded?</h2>
					<Button
						variant="primary"
						onClick={handleYesClick}
						loading={isLoading}
					>
						Yes
					</Button>

					<Button onClick={handleNoClick} loading={isLoading}>
						No
					</Button>
				</div>
			)}
		</div>
	)
}

export default NewMicroserviceForm
