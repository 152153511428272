import { FC, ReactNode } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'
import { FormField, Textarea } from '@cloudscape-design/components'

interface FormInputProps {
	label: string
	name: string
	control: Control
	description?: string
	info?: ReactNode
	rules?: UseControllerProps['rules']
	setFormValues: (name: string, value: any) => void
	placeholder?: string
	disabled?:boolean
}

const FormInputArea: FC<FormInputProps> = ({
	label,
	name,
	control,
	description,
	info,
	rules,
	setFormValues,
	placeholder,
	disabled
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<FormField
						label={label}
						description={description}
						info={info}
						errorText={error?.message}
					>
						<Textarea
							name={name}
							value={value}
							onChange={({ detail }) => {
								onChange(detail.value)
								setFormValues(name, detail.value)
							}}
							placeholder={placeholder}
							disabled={disabled}
						/>
					</FormField>
				)
			}}
		/>
	)
}

export default FormInputArea
