import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import Icon from '@cloudscape-design/components/icon'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'

import { useNewApiFormStore } from '../../../stores/apis/newApiFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewApi from '../../../hooks/mutate/useCreateNewApi'
import type { ApiRequest } from '../../../types/apis'
import api from '../../../api'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import useMicroservices from '../../../hooks/query/useMicroservices'
import useApplications from '../../../hooks/query/useApplications'
import useApis from '../../../hooks/query/useApis'
import type { MicroserviceRequest } from '../../../types/microservices'

const METHOD_LIST = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'DELETE', value: 'DELETE' }
]

const CODE_TEMPLATE_LIST = [
	{ label: 'NodeJS + CXS', value: 'cxs-nodejs' }
]

const NewApiForm: FC = () => {

	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const [microserviceOptions, setMicroserviceOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: microservices } = useMicroservices()
	const { data: apis } = useApis()

	const [applicationOptions, setApplicationOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: applications } = useApplications()

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewApiFormStore()

	const {
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	} = useGenericTagsManagementFormStore()

	const { mutate: createNewApi, isLoading } = useCreateNewApi()
	const { mutate: createTags, isLoading: isCreateTagsLoading } =
		useCreateNewTags()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid },
		watch,
		setError
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	interface envOpt {
		label: string
		value: string
	}

	const [isLoadingApplications, setIsLoadingApplications] = useState(true);
	const [isLoadingMicroservices, setIsLoadingMicroservices] = useState(true);

	const [isMockRequestDisabled, setisMockRequestDisabled] = useState(false);	
	useEffect(() => {
		if (microservices && microservices.length > 0) {
			const filteredMicroservices = microservices.filter((microservice) => {
				return (
					microservice.status === "APPROVED" &&
					microservice.applicationName.label === formValues.applicationName &&
					microservice.isMerged == true
				);
			});
			console.log("Filtered Microservices:", filteredMicroservices);

			const uniqueMicroservices: envOpt[] = []
			const microserviceNames = new Set()

			filteredMicroservices.forEach((microservice) => {
				if (!microserviceNames.has(microservice.microserviceName)) {
					microserviceNames.add(microservice.microserviceName)
					uniqueMicroservices.push({
						label: microservice.microserviceName,
						value: microservice.microserviceID
					})
				}
			})

			const extractedOptions = uniqueMicroservices
			setMicroserviceOptions(extractedOptions)
			setIsLoadingMicroservices(false)
		}

	}, [microservices, formValues.applicationName])

	// Use Applications for Dropdwon
	useEffect(() => {
		if (applications && applications.length > 0) {
			const filteredApplications = applications.filter(
				(application) => application.status === 'APPROVED'
			)
			const uniqueApplications: envOpt[] = []
			const applicationNames = new Set()

			filteredApplications.forEach((application) => {
				if (!applicationNames.has(application.details?.applicationName)) {
					applicationNames.add(application.details?.applicationName)
					uniqueApplications.push({
						label: application.details?.applicationName,
						value: application.applicationID
					})
				}
			})

			const extractedOptions = uniqueApplications
			setApplicationOptions(extractedOptions)
			setIsLoadingApplications(false)
		}
	}, [applications])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const api: ApiRequest = {
			requestorID: thisUserEmail,
			// tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		createTags(newTag, {
			onSuccess: () => {
				createNewApi(api, {
					onSuccess: () => {
						navigate('/apis')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}
	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === "applicationName") {
				setValue("microserviceName", "");
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, setValue]);


	// TEMPORARY APPLICATION VALUE FOR BETA API ONBOARDING - CXS
	useEffect(() => {
		if (applicationOptions.length > 0) {
			const cxsOption = applicationOptions.find(
				option => option.label === 'cxs'
			) || applicationOptions[0]

			setValue('applicationName', cxsOption)
			setFormValues('applicationName', cxsOption)
		}
	}, [applicationOptions, setValue, setFormValues])

	// TEMPORARY CODE TEMPLATE VALUE FOR BETA API ONBOARDING - CXS
	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === 'microserviceName') {
				const selectedMicroserviceId = value.microserviceName?.value;

				if (selectedMicroserviceId) {
					const selectedMicroservice = microservices?.find(
						(ms) => ms.microserviceID === selectedMicroserviceId
					);

					if (selectedMicroservice) {
						const templateOption = CODE_TEMPLATE_LIST[0];
						setFormValues('codeTemplate', templateOption);
						setValue('codeTemplate', templateOption, {
							shouldValidate: true,
							shouldDirty: true
						});
					}
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [watch, microservices, setFormValues, setValue]);

	// GET PLATFORM NAME
	useEffect(() => {
		const subscription = watch((value) => {
			const selectedApplicationId = value.applicationName?.value; 
			if (selectedApplicationId && applications) {
				// Find the selected application from the application list
				const selectedApplication = applications.find(
					(app) => app.applicationID === selectedApplicationId
				);
				if (selectedApplication) {
					const platformName = selectedApplication.details.platformName;

					setFormValues('platformName', platformName || '');
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [applications, setFormValues, watch]);

	useEffect(() => {
		const isGetOrDelete = formValues.httpMethod?.value === 'GET' || formValues.httpMethod?.value === 'DELETE';
		
		setisMockRequestDisabled(isGetOrDelete);
		
		if (isGetOrDelete) {
		  setValue('mockRequest', '{}', { shouldValidate: true });
		  setFormValues('mockRequest', '{}');
		} else {
			setValue('mockRequest', '');
			setFormValues('mockRequest', '');
		}
	  }, [formValues.httpMethod, setValue, setFormValues]);

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading || isCreateTagsLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Create Endpoint
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<ColumnLayout columns={2} variant="default">
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div style={{ width: '650px' }}>
									<FormSelect
										label="Application Name"
										name="applicationName"
										description='Select the application your microservice is under'
										control={control}
										rules={{
											required: 'Please select an option'
										}}
										setFormValues={setFormValues}
										options={isLoadingApplications
											? [{ label: 'Loading...', value: '' }]
											: applicationOptions.length === 0
												? [{ label: 'No applications to show', value: '' }]
												: applicationOptions
										}
										placeholder="Select Application"
										disabled={true}
									/>
								</div>
							</div>
							<FormSelect
								label="Microservice"
								name="microserviceName"
								control={control}
								rules={{
									required: 'Please select an option'
								}}
								description='Select the microservice your API will be under'
								setFormValues={setFormValues}
								options={
									isLoadingMicroservices
										? [{ label: 'Loading...', value: '' }]
										: microserviceOptions.length === 0
											? [{ label: 'No microservices to show', value: '' }]
											: microserviceOptions
								}
								placeholder="Select Microservice Name"
							/>
							{/* <div></div> */}
							<FormInput
								name="apiName"
								label="Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Display Name of API (e.g. GetPetName)"
								description="Enter the API name in pascal case"
								rules={{
									required: 'Please enter API name',
									minLength: {
										value: 5,
										message: 'Minumum length of 5 characters'
									},
									pattern: {
										value: /^[A-Z](?:[A-Z]*[a-z0-9]+)*[A-Z]*$/,
										message: 'API name should be pascal case and no whitespaces are allowed.'
									},
									validate: (value) => {
										if (apis?.some((api) => api.status === 'APPROVED' && api.apiName === value)) {
											return 'The API with the same name already exists';
										} else if (apis?.some((api) => api.status === 'PENDING_APPROVAL' && api.apiName === value)) {
											return 'The API with the same name is pending for approval';
										}
										return true;
									}
								}}
							/>
							<FormSelect
								label="Code Template"
								name="codeTemplate"
								control={control}
								description='Select the API code template'
								setFormValues={setFormValues}
								options={CODE_TEMPLATE_LIST}
								placeholder="Select Code Template"
								disabled={true}
								rules={{
									required: 'Please select a Code Template'
								}}
							/>
							<FormInput
								name="apiEndpoint"
								label="Path"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Endpoint Path"
								description="The endpoint URL/path"
								rules={{
									required: 'Please enter a path'
								}}
							/>
							<FormSelect
								label="HTTP Method"
								name="httpMethod"
								control={control}
								rules={{
									required: 'Please select an option'
								}}
								setFormValues={setFormValues}
								options={METHOD_LIST}
								placeholder="Select HTTP Method"
								description="Please select the HTTP method"

							/>
							<FormInput
								name="apiVersion"
								label="API Version"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="e.g., v1, v2"
								description="Enter the API Version"
								rules={{
									required: 'Please enter version',
									pattern: {
										value: /^v\d+(\.\d+)*$/,
										message: 'Input should be in the format v1, v1.0, v1.1.0, etc.'
									}
								}}
							/>
							<div style={ {display: 'none' }}>
								<FormInput
									name="platformName"
									label="Platform Name"
									type="text"
									control={control}
									setFormValues={setFormValues}
								/>
							</div>
						</ColumnLayout>
						<div style={{ display: 'flex', flexDirection: 'column', width: '150%' }}>
							<FormInputArea
								label="Description"
								name="description"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter description"
								description="Please describe the function of your API"

								rules={{
									required: 'Please enter a description'
								}}

							/>
						</div>
						<ColumnLayout columns={2} variant="default">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Request"
									name="mockRequest"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter mock request data"
									description="Please enter the mock request in a valid JSON format"
									disabled={isMockRequestDisabled}
									rules={{
										validate: {
											jsonFormat: (value) => {
												// Skip validation if disabled (GET or DELETE)
												if (isMockRequestDisabled) return true;
												try {
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Response"
									name="mockResponse"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Mock Response"
									description="Please enter the mock response in a valid JSON format"
									rules={{
										validate: {
											jsonFormat: (value) => {										
												try {
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											}
										}
									}}
								/>
							</div>
						</ColumnLayout>
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewApiForm
