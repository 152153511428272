import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Form, SpaceBetween, Alert, Container } from '@cloudscape-design/components'
import useAcceptMicroservice from '../../../hooks/mutate/useAcceptMicroservice'
import { useApprovalFormStore } from '../../../stores/microservices/approvalFormStore'
import type { MicroserviceApprovalRequest } from '../../../types/microservices'

interface ApprovalFormProps {
	thisMicroserviceID: string
	shouldShowApprovalForm: (shouldShow: boolean) => void
}

const ApprovalForm: FC<ApprovalFormProps> = ({
	thisMicroserviceID,
	shouldShowApprovalForm
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string
	const { mutate: acceptMicroservice, isLoading: isAccepting } = useAcceptMicroservice(thisMicroserviceID)
	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useApprovalFormStore()

	useEffect(() => {
		const approvervalue = thisUserEmail
		setFormValues('approverID', approvervalue)
	}, [thisUserEmail])

	const handleApprove = () => {
		const approvalRequest: MicroserviceApprovalRequest = {
			action: "APPROVE",
			microserviceID: thisMicroserviceID,
			approverID: thisUserEmail,
			details: formValues
		}
		acceptMicroservice(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				window.location.reload()
				navigate(`/microservices/${thisMicroserviceID}`)
			}
		});
	};

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						loading={isAccepting}
						onClick={handleApprove}
					>
						Approve
					</Button>
				</SpaceBetween>
			}
			>
			<Container>
				<Alert
					statusIconAriaLabel="Info"
					header="Are you sure you want to approve this request?"
					>
					Review the request details.
				</Alert>
			</Container>				
		</Form>
	)
}

export default ApprovalForm