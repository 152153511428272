import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRhocpTenants from '../../hooks/query/useRhocpTenants'
import { Table } from '@aws-northstar/ui'
import { Button, StatusIndicator, Flashbar, Link } from '@cloudscape-design/components'
import { RhocpTenant } from '../../types/rhocpTenant'

const RhocpTenants: FC = () => {
  const navigate = useNavigate()

  const { data: rhocpTenants, isLoading, isError, error } = useRhocpTenants()

  const [flashbarItems, setflashbarItems] = useState<Array<object>>([])

  useEffect(() => {
    if (isError) {
      setflashbarItems([...flashbarItems, {
        header: "Error",
        type: "error",
        content: `${error}`,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setflashbarItems([]),
        id: "message_1"
      }])
    }
  }, [isError, flashbarItems, error])

  const columnDefinitions = [
    {
      id: 'rhocpTenantID',
      width: 100,
      header: 'ID',
      cell: (data: RhocpTenant) => (
        <Link onFollow={() => { navigate(data.rhocpTenantID) }}>
          {data.rhocpTenantID}
        </Link>
      ),
      sortingField: 'rhocpTenantID'
    },
    {
      id: 'platformName',
      width: 100,
      header: 'System Name',
      cell: (data: RhocpTenant) => (
        <Link onFollow={() => { navigate(data.rhocpTenantID) }}>
          {data.details.platformName}
        </Link>
      ),
      sortingField: 'platformName'
    },
    {
      id: 'platformOwner',
      width: 100,
      header: 'System Owner',
      cell: (data: RhocpTenant) => data.details.platformOwner
    },
    {
      id: 'requestor',
      width: 100,
      header: 'Requested by',
      cell: (data: RhocpTenant) => data.requestorID || '-'
    },
    {
      id: 'approver',
      width: 100,
      header: 'Approved By',
      cell: (data: RhocpTenant) => data.approval?.approverID || data.approverID || '-'
    },
    {
      id: 'jiraTicket',
      width: 100,
      header: 'Jira Ticket',
      cell: (data: RhocpTenant) => data.jiraID || data.approval?.details.jiraTicket || '-'
    },
    {
      id: 'time',
      width: 100,
      header: 'Created At',
      cell: (data: RhocpTenant) => {
        if (data.creationTime) {
          const date = new Date(data.creationTime);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
          const day = date.getDate().toString().padStart(2, '0');

          return `${day}-${month}-${year}`;
        }
        return '';
      }
    },
    {
      id: 'status',
      width: 100,
      header: 'Status',
      cell: (data: RhocpTenant) => {
        const status = data.status
        switch (status) {
          case 'PENDING_APPROVAL':
            return (
              <StatusIndicator type="in-progress" colorOverride="blue">
                Pending Approval
              </StatusIndicator>
            )
          case 'APPROVED':
            return <StatusIndicator type="success">Approved</StatusIndicator>
          case 'REJECTED':
            return <StatusIndicator type="error">Rejected</StatusIndicator>
          default:
            return null
        }
      }
    }
  ]

  const tableActions = (
    <Button
      variant='primary'
      onClick={() => {
        navigate('/rhocpTenants/new')
      }}>
      New RHOCP Tenant
    </Button>
  )

  return (
    <>
      <Flashbar items={flashbarItems} />
      <Table
        header="Tenants"
        columnDefinitions={columnDefinitions}
        items={rhocpTenants || []}
        actions={tableActions}
        disableRowSelect
        loading={isLoading} />
    </>
  )
}

export default RhocpTenants